import React, { useState, useRef } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import { FaTrash, FaUpload } from 'react-icons/fa';

const servers = [
  { id: 1, name: 'Server 1', icon: 'logo192.png' },
  { id: 2, name: 'Server 2', icon: 'logo192.png' },
  { id: 3, name: 'Server 3', icon: 'logo192.png' },
];

const reasons = [
  { id: 1, reason: 'Spamming', proof: 'logo192.png' },
  { id: 2, reason: 'Inappropriate behavior', proof: 'logo192.png' },
  { id: 3, reason: 'Violation of server rules', proof: 'logo192.png' },
];

const App = () => {
  const [selectedServer, setSelectedServer] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [mistake, setMistake] = useState('');
  const [explanation, setExplanation] = useState('');
  const [apology, setApology] = useState('');
  const [files, setFiles] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const fileInputRef = useRef(null);

  const handleServerChange = (event) => {
    setSelectedServer(event.target.value);
  };

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleMistakeChange = (event) => {
    setMistake(event.target.value);
  };

  const handleExplanationChange = (event) => {
    setExplanation(event.target.value);
  };

  const handleApologyChange = (event) => {
    setApology(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const limitedFiles = selectedFiles.slice(0, 10 - files.length);
    setFiles([...files, ...limitedFiles]);
  };

  const handleFileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    const limitedFiles = droppedFiles.slice(0, 10 - files.length);
    setFiles([...files, ...limitedFiles]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedServer || !selectedReason || explanation.length < 200 || (mistake === 'own' && apology.trim() === '')) {
      setPopupMessage('Please fill in all the required fields and provide a valid explanation.');
      setShowPopup(true);
      return;
    }


    console.log('Unban request submitted!');
    console.log('Selected server:', selectedServer);
    console.log('Selected reason:', selectedReason);
    console.log('Mistake:', mistake);
    console.log('Explanation:', explanation);
    console.log('Apology:', apology);
    console.log('Files:', files);

  
    setSelectedServer(null);
    setSelectedReason(null);
    setMistake('');
    setExplanation('');
    setApology('');
    setFiles([]);

    setPopupMessage('Unban request submitted successfully!');
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="min-h-screen bg-gray-900 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-800 shadow-lg rounded-lg p-8">
          <h1 className="text-3xl font-bold mb-8 text-center text-white">Unban Request</h1>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label htmlFor="server" className="block text-sm font-medium text-gray-300">
                  Select Server
                </label>
                <select
                  id="server"
                  value={selectedServer}
                  onChange={handleServerChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-gray-700 border-gray-600 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md text-white"
                >
                  <option value="">Choose a server</option>
                  {servers.map((server) => (
                    <option key={server.id} value={server.id}>
                      {server.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="reason" className="block text-sm font-medium text-gray-300">
                  Ban Reason
                </label>
                <select
                  id="reason"
                  value={selectedReason}
                  onChange={handleReasonChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-gray-700 border-gray-600 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md text-white"
                >
                  <option value="">Select a reason</option>
                  {reasons.map((reason) => (
                    <option key={reason.id} value={reason.id}>
                      {reason.reason}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="mistake" className="block text-sm font-medium text-gray-300">
                Mistake
              </label>
              <select
                id="mistake"
                value={mistake}
                onChange={handleMistakeChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-gray-700 border-gray-600 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md text-white"
              >
                <option value="">Select an option</option>
                <option value="mistake">It was a mistake</option>
                <option value="mod">Incorrect moderator decision</option>
                <option value="own">It was my own fault</option>
              </select>
            </div>
            <div className="mt-6">
              <label htmlFor="explanation" className="block text-sm font-medium text-gray-300">
                Explanation (minimum 200 characters)
              </label>
              <textarea
                id="explanation"
                value={explanation}
                onChange={handleExplanationChange}
                rows="4"
                className="mt-1 block w-full bg-gray-700 border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-white"
                required
                minLength="200"
              ></textarea>
            </div>
            {mistake === 'own' && (
              <div className="mt-6">
                <label htmlFor="apology" className="block text-sm font-medium text-gray-300">
                  Apology
                </label>
                <textarea
                  id="apology"
                  value={apology}
                  onChange={handleApologyChange}
                  rows="4"
                  className="mt-1 block w-full bg-gray-700 border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-white"
                  required
                ></textarea>
              </div>
            )}
            <div className="mt-6">
              <label htmlFor="files" className="block text-sm font-medium text-gray-300">
                Upload Files (max 10)
              </label>
              <div
                className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <div className="space-y-1 text-center">
                  <FaUpload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-300">
                    <label
                      htmlFor="files"
                      className="relative cursor-pointer bg-gray-700 rounded-md font-medium text-indigo-400 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span>Upload files</span>
                      <input
                        id="files"
                        name="files"
                        type="file"
                        multiple
                        accept=".png,.jpg,.jpeg,.webp,.gif"
                        onChange={handleFileChange}
                        className="sr-only"
                        max="10"
                        ref={fileInputRef}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-400">PNG, JPG, JPEG, WEBP or GIF up to 10 files</p>
                </div>
              </div>
              <p className="mt-2 text-sm text-gray-300">{files.length}/10 files selected</p>
              <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {files.map((file, index) => (
                  <div key={index} className="relative">
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className="w-full h-32 object-cover rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => handleFileRemove(index)}
                      className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <FaTrash className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-6">
              <Turnstile siteKey={process.env.TURNSTIL_KEY} theme="dark" />
            </div>
            <div className="mt-8">
  <button
    type="submit"
    className="w-full bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  >
    Submit
  </button>
</div>
</form>
</div>
</div>
{showPopup && (
<div className="fixed inset-0 flex items-center justify-center z-50">
  <div className="absolute inset-0 bg-black opacity-75"></div>
  <div className="bg-gray-800 rounded-lg p-6 z-10">
    <p className="text-lg font-semibold mb-4 text-white">{popupMessage}</p>
    <button
      onClick={closePopup}
      className="bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      OK
    </button>
  </div>
</div>
)}
</div>
);
};

export default App;